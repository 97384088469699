<template>
    <div v-if="$can('access','PdfCodeVersion1')">
        <v-list-item class="font-sm" @click="viewPdf(contractItem)" v-if="!salesList && updateMode && listMode"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.contract') }}</v-list-item>
        <v-list-item class="font-sm" @click="viewSalePdf(contractItem)" v-if="salesList && updateMode && listMode"><v-icon color="red lighten-1" small class="mr-2">fas fa-file-pdf</v-icon>{{ $t('message.contract') }}</v-list-item>
    </div>
    <div v-else>
        <v-btn
            :loading="loading.contract"
            small
            class="ml-2 lighten-2"
            v-if="updateMode && !listMode"
            @click.stop="printContract()"
        >
            <v-icon small>far fa-file-pdf</v-icon>
            <span class="ml-1">{{ $t('message.pdf') }}</span>
        </v-btn>
        <v-list-item class="font-sm" @click.stop="printContract()" v-if="updateMode && listMode">
            <v-progress-circular :size="20" :width="2" indeterminate color="red lighten-1" class="mr-2" v-if="loading.contract"/>
            <v-icon color="red lighten-1" small class="mr-2" v-else>fas fa-file-pdf</v-icon>
            <span>{{ $t('message.contract') }}</span>
        </v-list-item>
        <PrintContractBlob
            :contract-id="contractId"
            :key="updateContractKey"
            @pdf-uploading="contractUploading"
            @pdf-uploaded="contractUploaded"
            @pdf-not-uploaded="contractNotUploaded"
            v-if="hasContract"
        />
    </div>
</template>

<script>

import {mapFields} from "vuex-map-fields";
import {api} from "Api";
import {log, makeId} from "Helpers/helpers";
import {v4 as uuidv4} from "uuid";

const PrintContractBlob = () => import('Views/v1/contracts/PrintContractBlob');
export default {
    name: "PrintContractButton",
    props: ['contractId','updateMode','listMode','salesList','contractItem'],
    components: {PrintContractBlob},
    data() {
        return {
            currentContractPdf: null,
            hasContract: false,
            loading: {
                contract: false,
            },
            updateContractKey: 1
        }
    },
    computed: {
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
    },
    methods: {
        getExistingPdf() {
            return new Promise((resolve, reject) => {
                api
                    .get("/contracts/" + this.contractId, {
                        params: {
                            fields: [
                                'contracts.location AS contracts__location'
                            ]
                        }
                    })
                    .then(response => {
                        const contract = response.data.data[0]
                        if(contract){
                            this.currentContractPdf = contract.Contract.location
                        }
                        resolve('success')
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        contractNotUploaded() {
            this.loading.contract = false
            this.hasContract = false
            this.$toast.error(this.$t('message.errors.couldNotPrint'),
                {
                    classes: ['icon-float-left'],
                    icon: 'error_outline'
                }
            )
        },
        contractUploaded(url) {
            this.currentContractPdf = url
            this.loading.contract = false
            this.viewFile(this.currentContractPdf)
            this.hasContract = false
        },
        contractUploading() {
            this.loading.contract = true
        },
        printContract() {
            this.loading.contract = true
            this.getExistingPdf()
                .then(()=>{
                    if(this.currentContractPdf != null){
                        this.viewFile(this.currentContractPdf)
                            .then(() => this.loading.contract = false)
                            .catch(() => this.loading.contract = false)
                    } else {
                        this.hasContract = true
                    }
                })
                .catch((error) => {
                    log(error)
                    this.loading.contract = false
                })
        },
        viewFile(file) {
            return new Promise((resolve,reject) => {
                try {
                    let tab = window.open(file + '?' + makeId(5), '_blank')
                    tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                    this.openedTabs.push(tab.name)
                    resolve('done')
                } catch(error) {
                    reject('error')
                }
            })
        },
        viewPdf (contract) {
            let document = encodeURIComponent(contract.Contract.version != null ? [contract.Contract.title, contract.Contract.revision_no , contract.Contract.partial_no].filter(Boolean).join('-') : contract.Contract.title)
            if([1,5].includes(contract.Contract.salestype_id)) {
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + document
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/contracts/print/'
                    + contract.Contract.id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    // + '&fileName=' + contract.Contract.title + '.pdf'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            } else if(contract.Contract.salestype_id == 2){
                if(contract.Contract.version != null){
                    if (contract.Contract.SignedOrder.location != null) {
                        let tab = window.open(contract.Contract.SignedOrder.location, "_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        if(contract.Contract.TmsSignedOrder.location != null){
                            let tab = window.open(contract.Contract.TmsSignedOrder.location,"_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.dialogs.error = true
                            this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                        }
                    }
                } else {
                    if(contract.Contract.TmsSignedOrder.location != null){
                        let tab = window.open(contract.Contract.TmsSignedOrder.location,"_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.dialogs.error = true
                        this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                    }
                }
            } else {
                return false
            }
        },
        viewSalePdf (contract) {
            let document = contract.Sale.document_version != null ? [contract.Sale.Contract.title, contract.Sale.Contract.revision_no , contract.Sale.Contract.partial_no].filter(Boolean).join('-') : contract.Sale.Contract.title
            if([1,5].includes(contract.Sale.salestype_id)) {
                let user = JSON.parse(localStorage.getItem('user'))
                let tab = window.open(
                    process.env.VUE_APP_PDF_SERVER
                    + '/api/render/'
                    + encodeURIComponent(document)
                    + '?url='
                    + process.env.VUE_APP_BASE_URL
                    + '/v1/contracts/print/'
                    + contract.Sale.document_id
                    + '/'
                    + uuidv4()
                    + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                    , "_blank")
                tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                this.openedTabs.push(tab.name)
            } else if(contract.Sale.salestype_id == 2){
                if(contract.Sale.document_version != null){
                    if (contract.Sale.SignedOrder.location != null) {
                        let tab = window.open(contract.Sale.SignedOrder.location, "_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        if(contract.Sale.TmsSignedOrder.location != null){
                            let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                            this.openedTabs.push(tab.name)
                        } else {
                            this.dialogs.error = true
                            this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                        }
                    }
                } else {
                    if(contract.Sale.TmsSignedOrder.location != null){
                        let tab = window.open(contract.Sale.TmsSignedOrder.location,"_blank")
                        tab.name = 'tab_' + Math.floor(Math.random() * 10000)
                        this.openedTabs.push(tab.name)
                    } else {
                        this.dialogs.error = true
                        this.dialogs.error_message = this.$t('message.signedContractNotAvailable')
                    }
                }
            } else {
                return false
            }
        }
    }
}
</script>

<style scoped>

</style>